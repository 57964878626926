import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

export const routes = {
  root: '/',
  swap: '/',
  admin: '/admin',
  iframe: '/iframe',
  volume: '/volume',
  landing: '/landing',
} as const;

export const router = createBrowserRouter([
  {
    path: routes.root,
    Component: lazy(() => import('@/layouts/iframe-layout')),
    children: [
      {
        path: routes.root,
        Component: lazy(() => import('@/pages/landing')),
      },
      {
        path: '*',
        Component: lazy(() => import('@/pages/not-found')),
      },
    ],
  },
]);
